// Color Variables
$primary-color: #0070C2;
$text-color: #333;
$text-color-dark: #222;
$text-color-light: #999;
$body-color: #fff;
$border-color: #E2E2E2;
$black: #000;
$white: #fff;
$light: #FBFBFB;

// Font Variables
$primary-font: 'Montserrat', sans-serif;
$icon-font: 'themify';
