/* Button style */
.btn {
  font-size: 16px;
  font-family: $primary-font;
  text-transform: capitalize;
  padding: 13px 30px;
  border-radius: 6px;
  border: 1px solid;
  position: relative;
  z-index: 1;
  transition: .2s ease;
  font-weight: 600;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &-sm{
    font-size: 14px;
    padding: 10px 25px;
  }
}

.btn-primary {
  background: $primary-color;
  color: $white;
  border-color: $primary-color;

  &:active,
  &:hover,
  &:focus,
  &.focus,
  &.active {
    background: darken($color: $primary-color, $amount: 10) !important;
    border-color: darken($color: $primary-color, $amount: 10) !important;
  }
}

.btn-outline-primary {
  background: transparent;
  color: $primary-color;
  border-color: $primary-color;

  &:active,
  &:hover,
  &:focus,
  &.focus,
  &.active {
    background: darken($color: $primary-color, $amount: 10) !important;
    border-color: darken($color: $primary-color, $amount: 10) !important;
    color: $white;
  }
}