/*  typography */
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800&display=swap');

body {
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.6;
  color: $text-color;
}

p, .paragraph {
  font-weight: 400;
  color: $text-color;
  font-size: 16px;
  line-height: 26px;
  font-family: $primary-font;
}

h1,h2,h3,h4,h5,h6 {
  color: $text-color-dark;
  font-family: $primary-font;
  font-weight: 700;
  line-height: 1.2;
}

h1, .h1{
  font-size: 50px;
  font-weight: 800;
  @include mobile {
    font-size: 36px;
    font-weight: 600;
  }
}

h2, .h2{
  font-size: 42px;
  font-weight: 800;
  @include mobile {
    font-size: 32px;
    font-weight: 600;
  }
}

h3, .h3{
  font-size: 32px;
  @include mobile {
    font-size: 28px;
  }
}

h4, .h4{
  font-size: 26px;
}

h5, .h5{
  font-size: 22px;
}

h6, .h6{
  font-size: 18px;
}
