/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT:	Project Name
VERSION:	Versoin Number
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[TABLE OF CONTENTS]
-------------------------------------------------------------------*/
/*  typography */
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800&display=swap");
body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

p, .paragraph {
  font-weight: 400;
  color: #333;
  font-size: 16px;
  line-height: 26px;
  font-family: "Montserrat", sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: #222;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 50px;
  font-weight: 800;
}
@media (max-width: 575px) {
  h1, .h1 {
    font-size: 36px;
    font-weight: 600;
  }
}

h2, .h2 {
  font-size: 42px;
  font-weight: 800;
}
@media (max-width: 575px) {
  h2, .h2 {
    font-size: 32px;
    font-weight: 600;
  }
}

h3, .h3 {
  font-size: 32px;
}
@media (max-width: 575px) {
  h3, .h3 {
    font-size: 28px;
  }
}

h4, .h4 {
  font-size: 26px;
}

h5, .h5 {
  font-size: 22px;
}

h6, .h6 {
  font-size: 18px;
}

/* Button style */
.btn {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  padding: 13px 30px;
  border-radius: 6px;
  border: 1px solid;
  position: relative;
  z-index: 1;
  transition: 0.2s ease;
  font-weight: 600;
}
.btn:hover, .btn:active, .btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.btn-sm {
  font-size: 14px;
  padding: 10px 25px;
}

.btn-primary {
  background: #0070C2;
  color: #fff;
  border-color: #0070C2;
}
.btn-primary:active, .btn-primary:hover, .btn-primary:focus, .btn-primary.focus, .btn-primary.active {
  background: #00538f !important;
  border-color: #00538f !important;
}

.btn-outline-primary {
  background: transparent;
  color: #0070C2;
  border-color: #0070C2;
}
.btn-outline-primary:active, .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary.focus, .btn-outline-primary.active {
  background: #00538f !important;
  border-color: #00538f !important;
  color: #fff;
}

body {
  background-color: #fff;
  overflow-x: hidden;
}

::selection {
  background: #008df5;
  color: #fff;
}

/* preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  vertical-align: middle;
  border: 0;
  border-radius: 6px;
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

a {
  color: inherit;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: 0.2s ease;
}
a:focus,
button:focus,
select:focus {
  outline: 0;
}

a:hover {
  color: #0070C2;
}

.slick-slide {
  outline: 0;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}
.section-sm {
  padding-top: 50px;
  padding-bottom: 50px;
}
.section-title {
  margin-bottom: 40px;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.border-default {
  border-color: #E2E2E2 !important;
}

/* overlay */
.overlay {
  position: relative;
}
.overlay::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
}

.outline-0 {
  outline: 0 !important;
}

.rounded, .card-icon, .card {
  border-radius: 12px !important;
}

.shadow, .card, .search-wrapper .form-control, .navigation.nav-bg {
  box-shadow: 0px 15px 60px rgba(62, 62, 62, 0.102) !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: #0070C2 !important;
}

.text-primary {
  color: #0070C2 !important;
}

.text-light {
  color: #999 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.zindex-1 {
  z-index: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.font-weight-medium {
  font-weight: 500;
}

.navigation {
  transition: 0.2s ease;
}
.navigation.nav-bg {
  background: #fff;
}
@media (max-width: 991px) {
  .navigation .form-inline {
    width: 100%;
  }
  .navigation .form-inline input {
    width: 100%;
  }
}

.nav-item {
  margin: 0;
}
.nav-item .nav-link {
  text-transform: capitalize;
}

.navbar-light .navbar-nav .nav-link {
  color: #222;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #0070C2;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: 20px;
  font-size: 15px;
}
@media (max-width: 991px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 10px;
    display: block;
  }
}

.search-wrapper {
  position: relative;
}
.search-wrapper .form-control {
  height: 46px;
  border: 0;
  outline: 0;
}
.search-wrapper .form-control::placeholder {
  color: #999;
  font-size: 15px;
}
.search-wrapper .form-control-lg {
  height: 70px;
}
.search-wrapper .form-control.active.focus-visible {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.search-wrapper button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.search-wrapper button i {
  color: #0070C2;
}

.card {
  transition: 0.2s ease;
  border: 0;
}
.card-lg .card-body {
  padding: 35px;
}
.card-icon {
  background: #0070C2;
  color: #fff;
  height: 55px;
  width: 60px;
  line-height: 55px;
  text-align: center;
  font-size: 27px;
  display: block;
  margin-top: 0px;
}
.card-text {
  margin-bottom: 0;
}
.card-title {
  font-weight: 700;
}
.card-body {
  padding: 25px;
}
.card:hover {
  box-shadow: 0px 15px 60px rgba(62, 62, 62, 0.2) !important;
}
.card ol,
.card ul {
  padding-left: 20px;
}
.card ol li,
.card ul li {
  margin-bottom: 10px;
}
.card ul {
  padding-left: 0;
  margin-bottom: 20px;
}
.card ul li {
  padding-left: 20px;
  position: relative;
}
.card ul li::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #0070C2;
  left: 3px;
  top: 8px;
}

.social-icons {
  margin-bottom: 0;
}
.social-icons a {
  display: block;
  height: 42px;
  width: 42px;
  line-height: 42px;
  text-align: center;
  color: #fff;
  background: #0070C2;
  border-radius: 6px;
}

.footer-menu {
  margin-bottom: 0;
}
.footer-menu a {
  display: block;
  padding: 10px;
  color: #333;
  transition: 0.2s ease;
}
.footer-menu a:hover {
  color: #0070C2;
}

/* sidenav */
.sidenav {
  border-right: 1px solid #E2E2E2;
  padding-right: 20px;
}
.sidenav ul {
  padding-left: 10px;
}
.sidenav > li a, .sidenav > li div {
  padding: 20px 0;
  color: #222;
  display: block;
  font-size: 18px;
  font-weight: 500;
}
.sidenav > li:not(:last-child) {
  border-bottom: 1px solid #E2E2E2;
}
.sidenav .sidelist ul {
  display: block;
  margin-bottom: 10px;
}
.sidenav .sidelist ul a {
  padding: 5px 0;
  font-size: 14px;
  font-weight: 400;
  color: #222;
}
.sidenav .sidelist ul li.active > a {
  margin-left: 10px;
}
.sidenav .sidelist ul li.active > a::before {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: block;
  top: 13px;
}
.sidenav .parent > a, .sidenav .parent div {
  color: #0070C2 !important;
  position: relative;
  margin-left: 10px;
}
.sidenav .parent > a::before, .sidenav .parent div::before {
  position: absolute;
  content: "";
  left: -10px;
  top: 25%;
  height: 50%;
  width: 1px;
  background: #0070C2;
}
.sidenav .parent ul {
  display: block;
  margin-bottom: 10px;
}
.sidenav .parent ul a {
  padding: 5px 0;
  font-size: 14px;
  font-weight: 400;
  color: #222;
}
.sidenav .parent ul li.active > a {
  margin-left: 10px;
}
.sidenav .parent ul li.active > a::before {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  display: block;
  top: 13px;
}

/* /sidenav */
.page-list {
  list-style-type: none;
}
.page-list a {
  display: none;
}
.page-list ul {
  padding: 0;
}
.page-list ul a {
  display: inline-block;
  padding: 7px 0;
  font-size: 20px;
  color: #222;
}

.pagination {
  margin-top: 50px;
  justify-content: space-between;
}
.pagination a {
  color: #0070C2;
  font-size: 18px;
  font-weight: 500;
}
.pagination i {
  line-height: 1.6;
}

.code-tabs {
  border: 1px solid #E2E2E2;
  overflow: hidden;
  margin-bottom: 20px;
}
.code-tabs .nav-tabs {
  margin-bottom: 0;
}
.code-tabs .nav-tabs .nav-item {
  padding-left: 0;
  border-right: 1px solid #E2E2E2;
}
.code-tabs .nav-tabs .nav-item .nav-link {
  text-decoration: none;
  font-weight: 500;
  border: 0;
}
.code-tabs .nav-tabs .nav-item::before {
  display: none;
}
.code-tabs .nav-tabs .nav-item.active {
  background: #0070C2;
}
.code-tabs .nav-tabs .nav-item.active .nav-link {
  color: #fff;
}
.code-tabs .tab-content {
  padding: 20px 15px;
}

pre {
  position: relative;
}
pre .copy-to-clipboard {
  background: #0070C2;
  color: #fff;
  cursor: pointer;
  padding: 4px 5px;
  line-height: 1;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  position: absolute;
  right: 10px;
  top: 10px;
}

:not(pre) > code + span.copy-to-clipboard {
  display: none;
}

.autocomplete-suggestions {
  border: 0;
  border-radius: 0 0 6px 6px;
  box-shadow: 0px 50px 60px rgba(62, 62, 62, 0.102) !important;
  background: #fff;
}
.autocomplete-suggestions .autocomplete-suggestion {
  transition: 0.2s ease;
  padding: 10px;
  font-weight: 500;
}
.autocomplete-suggestions .autocomplete-suggestion.selected {
  background: #0070C2;
}

.search-item a {
  color: #222;
  display: block;
}
.search-item a:hover {
  color: #0070C2;
}
.search-item:not(:last-child) {
  border-bottom: 1px solid #E2E2E2;
}

.form-group label {
  font-size: 15px;
  font-weight: 500;
}
.form-group .form-control {
  height: 40px;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #E2E2E2;
  border-radius: 0;
  padding: 10px 0;
}
.form-group .form-control::placeholder {
  font-size: 13px;
  color: #999;
}
.form-group .form-control:focus {
  outline: 0;
  border-color: #0070C2;
  box-shadow: none;
}
.form-group .custom-select {
  font-size: 13px;
}
.form-group textarea.form-control {
  height: 100px;
}

.badge {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 15px;
  padding: 5px 10px;
}
.badge.added {
  background: #3778FF;
  color: #fff;
}
.badge.fixed {
  background: #F54773;
  color: #fff;
}
.badge.changed {
  background: #3AAA55;
  color: #fff;
}
.badge.depricated {
  background: #343434;
  color: #fff;
}
.badge.removed {
  background: #3bbfe4;
  color: #fff;
}
.badge.security {
  background: #F53535;
  color: #fff;
}
.badge.unreleased {
  background: #A037FF;
  color: #fff;
}

.collapse-wrapper {
  padding: 5px 15px;
}
.collapse-wrapper .collapse-head {
  text-decoration: none;
  color: #333;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
}

:not(.highlight) pre {
  background: #FBFBFB;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  font-weight: 500;
}
.content strong {
  font-weight: 600;
}
.content a {
  text-decoration: underline;
  color: #0070C2;
}
.content ol,
.content ul {
  padding-left: 20px;
}
.content ol li,
.content ul li {
  margin-bottom: 10px;
}
.content ul {
  padding-left: 0;
  margin-bottom: 20px;
}
.content ul li {
  padding-left: 20px;
  position: relative;
}
.content ul li::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #0070C2;
  left: 3px;
  top: 8px;
}
.content table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  border: 1px solid #EEEEEE;
}
.content table td,
.content table th {
  padding: 0.75rem;
  vertical-align: top;
  margin-bottom: 0;
}
.content tr:not(:last-child) {
  border-bottom: 1px solid #EEEEEE;
}
.content th {
  font-weight: 500;
}
.content thead {
  background: #FBFBFB;
  margin-bottom: 0;
}
.content thead tr {
  border-bottom: 1px solid #EEEEEE;
}
.content tbody {
  background: #fff;
  margin-bottom: 0;
}
.content .notices {
  margin: 2rem 0;
  position: relative;
  overflow: hidden;
}
.content .notices p {
  padding: 10px;
  margin-bottom: 0;
}
.content .notices p::before {
  position: absolute;
  top: 2px;
  color: #fff;
  font-family: "themify";
  font-weight: 900;
  content: "\e717";
  left: 10px;
}
.content .notices.note p {
  border-top: 30px solid #6ab0de;
  background: #e7f2fa;
}
.content .notices.note p::after {
  content: "Note";
  position: absolute;
  top: 2px;
  color: #fff;
  left: 2rem;
}
.content .notices.tip p {
  border-top: 30px solid #78C578;
  background: #E6F9E6;
}
.content .notices.tip p::after {
  content: "Tip";
  position: absolute;
  top: 2px;
  color: #fff;
  left: 2rem;
}
.content .notices.info p {
  border-top: 30px solid #F0B37E;
  background: #FFF2DB;
}
.content .notices.info p::after {
  content: "Info";
  position: absolute;
  top: 2px;
  color: #fff;
  left: 2rem;
}
.content .notices.warning p {
  border-top: 30px solid #E06F6C;
  background: #FAE2E2;
}
.content .notices.warning p::after {
  content: "Warning";
  position: absolute;
  top: 2px;
  color: #fff;
  left: 2rem;
}
.content blockquote {
  font-size: 20px !important;
  color: #222;
  padding: 20px 40px;
  border-left: 2px solid #0070C2;
  margin: 40px 0;
  font-weight: bold;
  background: #FBFBFB;
}
.content blockquote p {
  margin-bottom: 0 !important;
}
.content pre {
  display: block;
  padding: 9.5px;
  margin: 10px 0px 10px;
}
.content code {
  margin-bottom: 0 !important;
  font-size: 100%;
}

img.scale {
  transition: transform 0.2s;
  margin: 0 auto;
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

img.scale.active {
  transform: scale(2.5);
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}