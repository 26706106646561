body {
  background-color: $body-color;
  overflow-x: hidden;
}

::selection {
  background: lighten($color: $primary-color, $amount: 10);
  color: $white;
}

/* preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  vertical-align: middle;
  border: 0;
  border-radius: 6px;
  max-width: 100%;
  height: auto;
}

ul {
  list-style: none;
}

a {
  color: inherit;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: .2s ease;

  &:focus {
    outline: 0;
  }
}

a:hover {
  color: $primary-color;
}

.slick-slide {
  outline: 0;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;

  &-sm {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &-title {
    margin-bottom: 40px;
  }
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.border-default {
  border-color: $border-color !important;
}

/* overlay */

.overlay {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: $black;
    opacity: .5;
  }
}

.outline-0 {
  outline: 0 !important;
}

.rounded {
  border-radius: 12px !important;
}

.shadow {
  box-shadow: 0px 15px 60px rgba(62, 62, 62, 0.102) !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: $primary-color !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-light {
  color: $text-color-light !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.zindex-1 {
  z-index: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.font-weight-medium{
  font-weight: 500;
}
